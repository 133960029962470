/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import store_67553650 from '/home/nde/pim/frontend/modules/@ergonode/ui/src/store/draggable/index.js';
import store_1723853e from '/home/nde/pim/frontend/modules/@ergonode/attributes/src/store/attribute/index.js';
import store_ecde068e from '/home/nde/pim/frontend/modules/@ergonode/attribute-groups/src/store/attributeGroup/index.js';
import store_6f35e577 from '/home/nde/pim/frontend/modules/@ergonode/authentication/src/store/authentication/index.js';
import store_78a775d6 from '/home/nde/pim/frontend/modules/@ergonode/categories/src/store/category/index.js';
import store_59bf0bfe from '/home/nde/pim/frontend/modules/@ergonode/category-trees/src/store/categoryTree/index.js';
import store_5dc20e44 from '/home/nde/pim/frontend/modules/@ergonode/comments/src/store/comment/index.js';
import store_43e8bbde from '/home/nde/pim/frontend/modules/@ergonode/conditions/src/store/condition/index.js';
import store_6ceb0b92 from '/home/nde/pim/frontend/modules/@ergonode/core/src/store/unit/index.js';
import store_58cd615d from '/home/nde/pim/frontend/modules/@ergonode/core/src/store/tab/index.js';
import store_9672dc90 from '/home/nde/pim/frontend/modules/@ergonode/core/src/store/list/index.js';
import store_5413cc9e from '/home/nde/pim/frontend/modules/@ergonode/core/src/store/feedback/index.js';
import store_6f324562 from '/home/nde/pim/frontend/modules/@ergonode/core/src/store/dictionaries/index.js';
import store_c9ba3852 from '/home/nde/pim/frontend/modules/@ergonode/core/src/store/core/index.js';
import store_a660e014 from '/home/nde/pim/frontend/modules/@ergonode/core/src/store/alert/index.js';
import store_7cf8935e from '/home/nde/pim/frontend/modules/@ergonode/channels/src/store/channel/index.js';
import store_755d1f82 from '/home/nde/pim/frontend/modules/@ergonode/media/src/store/media/index.js';
import store_3a18631e from '/home/nde/pim/frontend/modules/@ergonode/notifications/src/store/notification/index.js';
import store_7968cd38 from '/home/nde/pim/frontend/modules/@ergonode/collections/src/store/collection/index.js';
import store_32ffe2de from '/home/nde/pim/frontend/modules/@ergonode/products/src/store/product/index.js';
import store_2e64a01c from '/home/nde/pim/frontend/modules/@ergonode/product-statuses/src/store/productStatus/index.js';
import store_334be6ba from '/home/nde/pim/frontend/modules/@ergonode/product-templates/src/store/productTemplate/index.js';
import store_33b7e15e from '/home/nde/pim/frontend/modules/@ergonode/segments/src/store/segment/index.js';
import store_37f5fbda from '/home/nde/pim/frontend/modules/@ergonode/status-transitions/src/store/statusTransition/index.js';
import store_cbefe670 from '/home/nde/pim/frontend/modules/@ergonode/roles/src/store/role/index.js';
import store_c051c144 from '/home/nde/pim/frontend/modules/@ergonode/users/src/store/user/index.js';
import store_459e9fd7 from '/home/nde/pim/frontend/modules/@ergonode/import/src/store/import/index.js';
import store_8c8cdc82 from '/home/nde/pim/frontend/modules/@ergonode/workflow/src/store/workflowConditions/index.js';
import store_03792957 from '/home/nde/pim/frontend/modules/@ergonode/workflow/src/store/workflow/index.js';

export default {
	draggable: store_67553650,
	attribute: store_1723853e,
	attributeGroup: store_ecde068e,
	authentication: store_6f35e577,
	category: store_78a775d6,
	categoryTree: store_59bf0bfe,
	comment: store_5dc20e44,
	condition: store_43e8bbde,
	unit: store_6ceb0b92,
	tab: store_58cd615d,
	list: store_9672dc90,
	feedback: store_5413cc9e,
	dictionaries: store_6f324562,
	core: store_c9ba3852,
	alert: store_a660e014,
	channel: store_7cf8935e,
	media: store_755d1f82,
	notification: store_3a18631e,
	collection: store_7968cd38,
	product: store_32ffe2de,
	productStatus: store_2e64a01c,
	productTemplate: store_334be6ba,
	segment: store_33b7e15e,
	statusTransition: store_37f5fbda,
	role: store_cbefe670,
	user: store_c051c144,
	import: store_459e9fd7,
	workflowConditions: store_8c8cdc82,
	workflow: store_03792957,
};
